import { API, graphqlOperation } from "aws-amplify";

import { Material, Product, SummarizedMaterialOutput } from "API";
import {
  productsByProjectId,
  productsByScheduleId,
  materialsByGroupId,
  listSummarizedMaterials,
} from "graphql/queries";

export async function getProductDataByScheduleId(
  scheduleId: string
): Promise<Product[]> {
  const p = (
    await API.graphql(graphqlOperation(productsByScheduleId, { scheduleId }))
  ).data.productsByScheduleId;

  const products: Product[] = p.items.map((item: any) => {
    return {
      __typename: "Product",
      ...item,
    };
  });

  return Promise.resolve(products);
}

export async function getProductDataByProjectId(
  projectId: string
): Promise<Product[]> {
  const p = (
    await API.graphql(graphqlOperation(productsByProjectId, { projectId }))
  ).data.productsByProjectId;

  const products: Product[] = p.items.map((item: any) => {
    return {
      __typename: "Product",
      ...item,
    };
  });

  return Promise.resolve(products);
}

export async function listMaterials(groupId: string): Promise<Material[]> {
  const materials = (
    await API.graphql(graphqlOperation(materialsByGroupId, { groupId }))
  ).data.materialsByGroupId;

  return materials;
}

export async function listSummarizedMaterialsQuery(
  groupId: string,
  materialTypeId: string,
  startDate: string,
  endDate: string
): Promise<SummarizedMaterialOutput[]> {
  const materials = (
    await API.graphql(
      graphqlOperation(listSummarizedMaterials, {
        input: {
          groupId,
          materialTypeId,
          startDate,
          endDate,
        },
      })
    )
  ).data.listSummarizedMaterials.items;

  return materials;
}
